var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center" },
    _vm._l(this.params.value, function (servicePackage, i) {
      return _c("span", { key: i }, [
        _vm._v(
          "\n      " +
            _vm._s(servicePackage.service_package.name) +
            "\n      " +
            _vm._s(servicePackage.service_package.category_cnh) +
            "\n    "
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }