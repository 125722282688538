var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { direction: _vm.$vs.rtl ? "rtl" : "ltr" } },
    [
      _c("feather-icon", {
        attrs: {
          icon: "Edit3Icon",
          svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.editRecord },
      }),
      _c("feather-icon", {
        attrs: {
          icon: "Trash2Icon",
          svgClasses: "h-5 w-5 hover:text-danger cursor-pointer",
        },
        on: { click: _vm.confirmDeleteRecord },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }