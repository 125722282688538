<template>
  <div class="flex items-center">
      <span v-for="(servicePackage, i) in this.params.value" :key="i">
        {{servicePackage.service_package.name}}
        {{servicePackage.service_package.category_cnh}}
      </span>
  </div>
</template>

<script>
export default {
  name: 'CellRendererListServices'

}
</script>
