<template>
    <div v-else-if="params.value" class="flex items-center">
        {{ situation }}
    </div>
</template>

<script>
export default {
    name: 'CellRendererSituation',
    computed: {
        situation () {
            if (this.params.value) {
                const status = this.params.value[0].situation_contract;
                return status === 'IN_PROGRESS' ? 'Em Andamento' : (status === 'FINISHED' ? 'Finalizado' : (status === 'CANCELED' ? 'Cancelado' : ''));
            }
        }
    }
}
</script>
