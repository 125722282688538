<template>
    <div v-else-if="params.value" class="flex items-center">
        {{ situation }}
    </div>
</template>

<script>
export default {
    name: 'CellRendererListStudentSituation',
    computed: {
        situation () {
            return this.params.value == 1 ? 'ATIVO' : 'INATIVO'
        }
    }
}
</script>
